import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

interface Props {
    description: string;
    title: string;
}

export default function SEO(props: Props) {
    const { description, title } = props;

    return (
        <HelmetProvider>
            <Helmet
                htmlAttributes={{
                    lang: 'en',
                }}
                title={title}
                meta={[
                    {
                        name: 'description',
                        content: description,
                    },
                ]}
            />
        </HelmetProvider>
    );
}
