import React from 'react';

import SEO from '../../components/SEO/index';
import { useTranslation } from 'react-i18next';

export default function NotFoundPage() {
    const { t } = useTranslation('', { useSuspense: false });
    return (
        <>
            <SEO title={t('notFoundPage.title')} description={'Page not found'} />
            <h1>{t('notFoundPage.heading')}</h1>
            <p>{t('notFoundPage.message')}</p>
        </>
    );
}
